import ReactBilling from '@web-solutions/react-billing';

import API from './api';

import { getExtraParams } from './utils';

const Billing = {
  paymentSystemProject: '',
  paymentSystemType: '',

  setApiAuth(apiAuth) {
    this.apiAuth = apiAuth;
  },

  getPaymentParams({ paymentSystem, paymentProject } = {}) {
    return {
      payment_system_type: paymentSystem || this.paymentSystemType,
      payment_system_project: paymentProject || this.paymentSystemProject,
    };
  },

  getHeaders() {
    return {
      Authorization: this.apiAuth,
    };
  },

  async init(paymentSystem, paymentProject) {
    this.paymentSystemType = paymentSystem;
    this.paymentSystemProject = paymentProject;

    const headers = this.getHeaders();

    const keys = await API.getPublicKey(this.getPaymentParams(), headers);

    return ReactBilling.init(paymentSystem, keys);
  },

  async getPrices(pricesIds, { paymentSystem } = {}) {
    const headers = this.getHeaders();
    const params = {
      search: `external_id:${pricesIds.join(',')}`,
      searchFields: 'external_id:in',
      ...this.getPaymentParams({ paymentSystem }),
    };
    return (await API.getPrices(params, headers)).data;
  },

  async createOrder({
    one_time_product,
    one_time_products,
    productId,
    orderDescription,
    products,
    currency,
    paymentSystem,
    trigger,
    metadata,
    solidMetadata,
    is_sending_terminate_links,
    is_sending_trial_end
  }) {
    const extra = await getExtraParams();

    const body = {
      ...this.getPaymentParams({ paymentSystem }),
      ...extra,
      one_time_product,
      trigger,
      metadata: JSON.stringify(metadata),
      solid_metadata: solidMetadata,
      one_time_products: one_time_products || undefined,
      product_id: productId,
      order_description: orderDescription,
      products,
      currency,
      is_sending_terminate_links,
      is_sending_trial_end
    };

    return API.createOrder(body, this.getHeaders());
  },

  async initAlternativePayment({
    paymentMethod,
    paymentSystem,
    trigger,
    products,
    currency,
    metadata,
    one_time_products,
    orderDescription,
    is_sending_terminate_links,
    is_sending_trial_end,
    solid_metadata,
    first_name,
    last_name,
    taxpayer_id,
    success_url,
    fail_url,
    return_url,
  }) {
    const extra = await getExtraParams();

    const body = {
      ...this.getPaymentParams({ paymentSystem }),
      ...extra,
      products,
      solid_metadata,
      currency,
      metadata: JSON.stringify(metadata),
      one_time_products,
      trigger,
      order_description: orderDescription,
      is_sending_terminate_links,
      is_sending_trial_end,
      payment_method: paymentMethod,
      customer_first_name: first_name,
      customer_last_name: last_name,
      taxpayer_id,
      success_url,
      fail_url,
      return_url,
    };

    return API.initAlternativePayment(body, this.getHeaders())
  },

  async createCheckout({ productId, checkoutId, paymentMethod, successUrl, paymentSystem }) {
    const extra = await getExtraParams();
    const body = {
      ...this.getPaymentParams({ paymentSystem }),
      ...extra,
      product_id: productId,
      checkout_id: checkoutId,
      payment_method: paymentMethod,
      success_url: successUrl,
    };

    return API.createCheckout(body, this.getHeaders());
  },

  async createCustomer({ email, paymentSystem, paymentProject }) {
    const headers = this.getHeaders();
    const body = {
      ...this.getPaymentParams({ paymentSystem, paymentProject }),
      ...(email && { email }),
    };

    return (await API.createCustomer(body, headers)).data;
  },

  async subscribe({ paymentSystem, metadata, ...formData }) {
    const headers = this.getHeaders();
    const extra = await getExtraParams();

    const body = {
      ...extra,
      ...formData,
      ...this.getPaymentParams({ paymentSystem }),
      metadata: JSON.stringify(metadata),
    };

    return (await API.createSubscription(body, headers)).data;
  },

  async subscribeOnOneClick({ productId, metadata, ...params }) {
    const body = {
      ...this.getPaymentParams(),
      ...params,
      metadata: JSON.stringify(metadata),
      product_id: productId,
    };

    const response = await API.subscribeOnOneClick(body, this.getHeaders())

    return response
  },

  async applePayOrder({ paymentSystem, metadata, ...formData }) {
    const headers = this.getHeaders();
    const extra = await getExtraParams();

    const body = {
      ...extra,
      ...formData,
      customer_email: formData.email,
      product_id: formData.price_id,
      metadata: JSON.stringify(metadata),
      ...this.getPaymentParams({ paymentSystem }),
    };

    return API.createApplePayOrder(body, headers);
  },

  async getSubscriptions(isAsb) {
    const headers = this.getHeaders();
    if (isAsb) {
      return API.getAllSubscriptions(headers);
    }
    return API.getActualizedSubscriptions(headers);
  },

  async requestCancel({ email, trigger }) {
    const body = {
      email,
      trigger,
    };

    return API.cancelSubscription(body);
  },

  async submitCancelSubscription(params) {
    return API.submitCancelSubscription(params);
  },

  async createOneClickPayment({
    amount,
    currency,
    force3ds,
    productCode,
    description,
    successUrl,
    failUrl,
    trigger,
    metadata,
    solidMetadata,
    withTransactionDetailsEmail
  }) {
    const headers = this.getHeaders();

    const body = {
      ...this.getPaymentParams(),
      withTransactionDetailsEmail,
      force3ds,
      amount,
      description,
      currency,
      trigger,
      metadata: JSON.stringify(metadata),
      solid_metadata: solidMetadata,
      success_url: successUrl,
      fail_url: failUrl,
      product_code: productCode,
    };

    return API.createOneClickPayment(body, headers);
  },

  async getPaymentMethod() {
    const headers = this.getHeaders();
    const paymentMethod = await API.getPaymentMethod(headers);
    return paymentMethod?.data || paymentMethod;
  },

  async getOneTimePurchases() {
    const headers = this.getHeaders();
    const oneTimePurchases = await API.getOneTimePurchases(headers);
    return oneTimePurchases?.data || oneTimePurchases;
  },

  async sendAccessEmail(email) {
    const headers = this.getHeaders();
    const body = {
      email
    }

    return API.sendAccessEmail(body, headers);
  },

  async changePlan({ subscriptionId, productId, isNewSubscription, successUrl, failUrl }) {
    const headers = this.getHeaders();

    const body = {
      ...this.getPaymentParams(),
      subscriptionId,
      planCode: productId,
      isNewSubscription: !!isNewSubscription,
      success_url: successUrl,
      fail_url: failUrl,
    };

    return API.changePlan(body, headers);
  },

  async giveProduct(productId) {
    const headers = this.getHeaders();

    const body = {
      ...this.getPaymentParams(),
      product_code: productId,
    };

    return API.giveProduct(body, headers);
  }
};

export default Billing;
