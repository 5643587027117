import axios from 'axios';

export const request = (baseURL, headers = {}) => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  });

  instance.interceptors.response.use(
    (response) => response.data,
    async (error) => {
      const r = error?.response;
      const d = r?.data?.data || r?.data;
      if (d?.message || d?.three_d_secure_action_token_id) {
        const cause = Error(JSON.stringify(d, null, 2));
        cause.name = 'Server Error';
        const e = Error(d.message, { cause });
        e.name = d.name;
        e.status = d.status;
        e.code = d.code;
        e.data = d;
        throw e;
      }
      throw error;
    }
  )
  return instance;
};
