import { useCallback, useState } from 'react';

import validate from 'core/utils/validation';

export const useEmail = (defaultValue) => {
  const [value, setValue] = useState(defaultValue);
  const [isValid, setValidEmail] = useState(validate(defaultValue, 'isEmail'));
  const setVV = useCallback((val) => {
    setValue(val);
    setValidEmail(validate(val, 'isEmail'));
  }, []);
  return [value, isValid, setVV];
};
