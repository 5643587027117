import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SOULA_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    "API-TOKEN": process.env.REACT_APP_SOULA_API_TOKEN
  },
});

export default apiClient;
