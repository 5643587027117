export const addRecurlyDependencies = () => {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://js.recurly.com/v4/recurly.css';
    document.head.appendChild(link);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.src = 'https://js.recurly.com/v4/recurly.js';
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

export const addStripeDependencies = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.src = 'https://js.stripe.com/v3';
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

export const addSolidgateDependencies = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.src = 'https://cdn.solidgate.com/js/solid-form.js';
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

export const addPaddleDependencies = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};
