import { type FC, useState } from 'react'
import classNames from 'classnames';

import { T, t } from '@web-solutions/manage-subscription/src/localization';

import { Text } from '@web-solutions/core/ui-elements';

import { ContactUsModal } from '@web-solutions/core/components/contact-us-modal';

import classes from './style.module.scss'

const tKey = 'manage.main.info'

export const Info: FC = () => {
  const [isOpened, setIsOpened] = useState(false)

  const handleOpenModal = () => {
    setIsOpened(true)
  }

  const handleCloseModal = () => {
    setIsOpened(false)
  }

  return (
    <>
      <div className={classes.wrapper}>
        <Text className={classNames(classes.text, classes.main)}>
          <T
            k={`${tKey}.title`}
            components={{ contactusbutton: <button type='button' className={classes.contactUs} onClick={handleOpenModal} /> }}
          />
        </Text>
        <Text className={classes.text}>
          {t(`${tKey}.updating`)}
        </Text>
        <Text className={classNames(classes.text, classes.secondary)}>
          {t(`${tKey}.updating_description`)}
        </Text>
      </div>
      <ContactUsModal
        active={isOpened}
        openLocation='manage_sub'
        onCloseModal={handleCloseModal}
        onCloseModalAfterSubmit={handleCloseModal}
      />
    </>
  )
}
