import { useState, useEffect } from 'react';

import { checkPaymentMethod } from '@web-solutions/react-billing/utils/apple-pay';

export const useApplePayCanMakePayments = (withActiveCard: boolean): boolean | null => {
  const [value, setValue] = useState<boolean | null>(null);

  useEffect(() => {
    checkPaymentMethod(withActiveCard)
      .then(function (v: boolean) {
        setValue(v);
      });
  }, [withActiveCard]);

  return value;
};
