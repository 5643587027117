import { ImageSource } from "core/interfaces/images";

export interface LocalizedString {
  en: string;
  es: string;
  fr: string;
  pt: string;
  de: string;
  tr: string;
  it: string;
  cs: string;
  ro: string;
  pl: string;
  id: string;
  ar: string;
}

export interface LocalizedSupported {
  en: "English";
  es: "Spanish";
  fr: "French";
  pt: "Portuguese";
  de: "German";
  tr: "Turkish";
  it: "Italian";
  cs: "Czech";
  ro: "Romanian";
  pl: "Polish";
  id: "Indonesian";
  ar: "Arabic";
}

export interface LocalizedArray {
  en: string[];
  es: string[];
  fr: string[];
  pt: string[];
  de: string[];
  tr: string[];
  it: string[];
  cs: string[];
  ro: string[];
  pl: string[];
  id: string[];
  ar: string[];
}

export interface LocalizedImage {
  en: ImageSource;
  es: ImageSource;
  fr: ImageSource;
  pt: ImageSource;
  de: ImageSource;
  tr: ImageSource;
  it: ImageSource;
  cs: ImageSource;
  ro: ImageSource;
  pl: ImageSource;
  id: ImageSource;
  ar: ImageSource;
}

export interface RemoteConfigLocales {
  localizationEnabled: boolean;
  supportedLanguages: LocalizedSupported;
}

export const INITIAL_CONFIG_LOCALES: RemoteConfigLocales = {
  localizationEnabled: false,
  supportedLanguages: {} as LocalizedSupported,
};
