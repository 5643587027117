import { HEADERS, TRANSACTION_URL } from "./constants";
import instance from "./instance";

import { Purchase, RecordTransactionRequest } from "./types";

export const recordTransaction = async (
  chargeInvoice: any,
  idfm: string,
  purchase: Purchase,
): Promise<void> => {
  const requestBody: RecordTransactionRequest = {
    transaction: {
      order_id:
        chargeInvoice.payment_service === "solidgate"
          ? chargeInvoice.order_id
          : chargeInvoice.id,
      payment_service: chargeInvoice.payment_service||'recurly',
      product_id:
        chargeInvoice.payment_service === "solidgate"
          ? chargeInvoice.product_code
          : chargeInvoice.lineItems[0]?.itemCode,
      is_one_time_payment: true,
    },
    token: process.env.REACT_APP_MAGNUS_TOKEN!,
    idfm,
    internal_uid: purchase.email || "",
  };

  await instance.post(TRANSACTION_URL, requestBody, {
    headers: HEADERS,
  });
};
