// import moment from 'moment';
import queryString from 'query-string';

const answers: Record<string, string[]> = {}

export function trackEvent(event: string, params?: any) {
  if (params?.answer) {
    const answer = String(params?.answer);
    answers[event] = answers[event] || [];
    if (!answers[event].includes(answer)) {
      answers[event].push(answer);
    }
  }
}

export function getSubscriptionPrediction(): Promise<string> {
  return fetch('https://subtruck.magnus.ms/api/v2/transaction/test/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', },
    body: JSON.stringify({
      ...answers,
      // install_time: [
      //   moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      // ],
      // user_agent: [
      //   window.navigator.userAgent,
      // ],
      utm_source: [
        queryString.parse(window.location.search).utm_source,
      ],
    })
  })
    .then(r => {
      if (r.ok) {
        return r.text();
      } else {
        throw Error(String(r.status));
      }
    });
}