import React from 'react';

import { SUPPORT_EMAIL } from 'src/constants/general';

import { t } from '../../../localization';

import classes from './style.module.scss';

export const TerminateFooter: React.FC = () => (
  <footer className={classes.footer}>
    {t('terminate.footer.title')} - <a href={`mailto:${SUPPORT_EMAIL}`} className={classes.footer__link}>{SUPPORT_EMAIL}</a>
  </footer>
);
