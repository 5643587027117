import React from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import type { OnApproveData } from "@paypal/paypal-js";

import { getPublicKeys, PaymentSystem } from '@web-solutions/react-billing';

import Analytics from '@web-solutions/module-analytics';

import { ProductDetails } from 'core/store/billing/selectors';

import PayPalButton from './button';

interface Props {
  product: ProductDetails,
  isDisabled?: boolean,
  onSubmit: (formData: any) => void,
  className?: string,
  style?: {},
}

const getPaypalOptions = () => ({
  'client-id': getPublicKeys()?.paypal?.client_id,
  components: 'buttons',
  intent: 'subscription',
  vault: true,
  'disable-funding': 'credit,card',
});

const DEFAULT_CURRENCY = 'USD';

const customStyle = {
  shape: 'rect',
  layout: 'vertical',
  label: 'buynow',
  color: 'gold',
  tagline: 'false',
};

const PayPalProvider: React.FC<Props> = ({
  product,
  isDisabled = false,
  onSubmit,
  className,
  style,
}) => {
  const { currency, paypalPlanId, trialDays, amount, expireAt } = product;

  if (!paypalPlanId) return null;

  const handleClick = () => {
    Analytics.trackEvent('paypal', 'click');
  };

  const handleSuccess = async (data: OnApproveData) => {
    const { orderID, subscriptionID } = data;
    Analytics.trackEvent('paypal', 'submit');
    onSubmit({
      transaction_id: orderID,
      subscription_id: subscriptionID,
      trial_period_days: trialDays,
      amount,
      currency,
      price_id: paypalPlanId,
      expire_at: expireAt,
      method: 'paypal',
      paymentSystem: PaymentSystem.PAYPAL,
    });
  };

  const handleCancel = () => {
    Analytics.trackEvent('paypal', 'cancel');
  };

  const handleError = (err: { message: string; code: string; }) => {
    Analytics.trackEvent('paypal', 'error', { message: String(err?.message || err), code: String(err?.code || err) });
  };

  return (
    <PayPalScriptProvider deferLoading options={getPaypalOptions()}>
      <PayPalButton
        currency={currency ? currency.toUpperCase() : DEFAULT_CURRENCY}
        planId={paypalPlanId}
        isDisabled={isDisabled}
        style={{ ...customStyle, ...style }}
        className={className}
        onClick={handleClick}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
        onError={handleError}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalProvider;
