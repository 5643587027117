import DeviceProps from '@magnus/react-native-device-props';

// @ts-ignore
import { request as requestBillingApi } from '@web-solutions/module-billing/api/network';

const billingApi = requestBillingApi(process.env.REACT_APP_API_BILLING_URL);

const authDeviceId = (idfm: string) =>
  billingApi.post('/auth/device-id', { device_id: idfm, });

export const auth = () =>
  DeviceProps.getIDFM()
    .then(idfm => authDeviceId(idfm))
