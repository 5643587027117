import { ComponentProps } from "react";

import { useRemoteConfig } from "core/hooks/use-remote-config";
import { ImageSource } from "core/interfaces/images";

export interface ImageProps extends Omit<ComponentProps<'img'>, 'src' | 'srcSet' | 'alt'> {
  img: ImageSource,
  alt: string,
}

const Image: React.FC<ImageProps> = ({ img, alt, ...props }) => {
  const { theme } = useRemoteConfig();

  const mode = theme?.mode || '';

  const imgByTheme = img?.themes?.[mode] ?? img;

  return (
    <img
      src={imgByTheme?.src}
      srcSet={imgByTheme?.srcSet}
      alt={alt}
      {...props}
    />
  );

}

export default Image;