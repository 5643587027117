import { COUNTRY_VAT } from "@web-solutions/core/payment/constants"

type Params = {
  countryCode: string,
  isAvailable?: boolean
}

export const getVATMetadata = ({ countryCode, isAvailable = true }: Params) => (
  isAvailable ?
    { vatPercents: COUNTRY_VAT[countryCode]?.vat ?? null } :
    {}
)
