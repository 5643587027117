import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useApplePay from '../../hooks/use-apple-pay';

import { PaymentSystem } from '../../constants';
import { getPaymentSystem } from '../../utils/storage';

import classes from './style.module.css';

export const ApplePayButton = ({
  product,
  label,
  disabled,
  className,
  onClick,
  onSubmit,
}) => {
  const paymentSystem = getPaymentSystem();

  const [address, setAddress] = useState();

  const { id, amount, isIntroductory, trial_price_amount, currency } = product;

  const options = useMemo(() => ({
    label,
    amount: !isIntroductory ? amount : trial_price_amount,
    currency,
  }), [amount, isIntroductory, label, trial_price_amount, currency]);

  const { paymentRequest } = useApplePay(options,
    {
      onShippingAddress: (res) => {
        setAddress(res);
      },
      onMethod: (res) => {
        const formData = {
          price_id: id,
          method: 'applepay',
          shipping_address: address,
          currency,
        };

        if (paymentSystem === PaymentSystem.RECURLY) {
          if (res?.id) {
            formData.token_id = res.id;
          }
        } else if (paymentSystem === PaymentSystem.STRIPE) {
          const { trialDays, onetimePriceId } = product;

          const { complete, paymentMethod } = res;
          formData.payment_method_id = paymentMethod?.id;
          formData.trial_period_days = trialDays;
          if (isIntroductory && onetimePriceId) {
            formData.introductory_price_id = onetimePriceId;
          }
          complete('success');
        } else if (paymentSystem === PaymentSystem.SOLIDGATE) {
          Object.assign(formData, res);
        }

        onSubmit(formData);
      },
    }
  );

  if (!paymentRequest) {
    return null;
  }

  const handleClickButton = () => {
    if (onClick) {
      onClick();
    }
    if (paymentSystem === PaymentSystem.RECURLY) {
      paymentRequest.begin();
    } else if (paymentSystem === PaymentSystem.STRIPE) {
      paymentRequest.show();
    } else if (paymentSystem === PaymentSystem.SOLIDGATE) {
      paymentRequest.begin();
    }
  };

  const buttonClassNames = classNames(classes['apple-pay-button'], className);

  return (
    <button
      label='apple'
      type='button'
      disabled={disabled}
      className={buttonClassNames}
      onClick={handleClickButton}
    />
  );
};

ApplePayButton.propTypes = {
  product: PropTypes.object.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.any,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

ApplePayButton.defaultProps = {
  disabled: false,
  className: null,
  onClick: null,
};
