import classNames from "classnames";
import { useAppSelector } from "@web-solutions/store/types"

import type { ComponentProps, FC } from "react";

import { useSelector } from "react-redux";

import { T } from "@web-solutions/module-localization";

import { useRemoteConfig } from "core/hooks/use-remote-config";
import { COUNTRY_VAT } from "core/payment/constants";

import classes from "../../style.module.scss";

import { VatPrice } from "../vat-price";

import type { SimpleViewProps } from "./simple-view";

type TotalPriceProps = Pick<
  SimpleViewProps,
  "vatPrice" | "vat" | "fullPrice" | "actualPrice" | "mode"
> &
  ComponentProps<"div">;

export const TotalPrice: FC<TotalPriceProps> = ({
  vatPrice,
  vat,
  className,
  fullPrice,
  mode,
  actualPrice,
  ...props
}) => {
  const { configVAT, displayVAT, paymentPopup } = useRemoteConfig();

  const isSimple = configVAT.view === "simple";

  const locale = useAppSelector((state) => state.locale.locale);
  const countryCode = useSelector((state: any) => state?.billing?.countryCode);

  const displayTaxes = displayVAT && !!COUNTRY_VAT[countryCode];

  const priceString = displayTaxes && !isSimple ? vatPrice : actualPrice;

  return (
    <div className={classNames(classes.totalPrice, className)} {...props}>
      <div className={classes.row}>
        <p className={classes.aboutItemBlackText}>
          <T
            k={
              isSimple
                ? paymentPopup.total.total[locale]
                : paymentPopup.total.totalItem[locale]
            }
          />
        </p>
        <p className={classes.aboutItemBlackText}>
          {mode === "short" && (
            <span className={classes.full_price}>{fullPrice}</span>
          )}
          {priceString}
        </p>
      </div>
      <VatPrice priceText={actualPrice} vat={vat} vatPrice={vatPrice} />
    </div>
  );
};
