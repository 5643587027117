import { Button, Modal, Text, Title } from "@web-solutions/core/ui-elements"

import { t } from '../../../localization';

import { ReactComponent as IconClose } from '../icons/close-icon.svg';

import classes from './style.module.scss';

interface ErrorModalProps {
  tKey: string,
  isOpen: boolean,
  message: string,
  onButtonClick: () => void;
  onCloseClick: () => void;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  isOpen,
  tKey,
  message,
  onButtonClick,
  onCloseClick
}) => {
  return (
    <Modal
      isOpen={isOpen}
      className={classes.modal}
      overlayClassName={classes.overlay}
    >
      <Title level="h2" className={classes.title}>{t(`${tKey}.title`)}</Title>
      <Text className={classes.text}>{message}</Text>
      <button type="button" onClick={onCloseClick} className={classes.close}>
        <IconClose width="16px" height="16px" />
      </button>
      <Button
        onClick={onButtonClick}
        iconRight={false}
        className={classes.button}
        title={t(`${tKey}.button`)}
        titleClassName={classes.button__title}
      />
    </Modal>
  )
}
