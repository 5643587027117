import type { FC } from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { useRemoteConfig } from '@web-solutions/base-app/hooks/use-remote-config';

import { DefaultProgress } from './components/default';
import { SingleWordProgress } from './components/single-word';

import classes from './style.module.scss';

export type ProgressSteps = "order" | "offer" | 'guide' | 'account';

interface ProgressProps {
  currentStep?: ProgressSteps;
  stepsText?: Record<ProgressSteps, string>,
}

const getStepStatus = (index: number, currentIndex: number) => {
  if (index > currentIndex) {
    return 'next';
  }
  if (index === currentIndex) {
    return 'current';
  }
  return 'prev';
};

const tKey = 'subscription.purchase_progress';

const defaultStepsStatus: ProgressSteps[] = ['order', 'offer', 'guide'];

export const Progress: FC<ProgressProps> = ({ currentStep = 'offer', stepsText }) => {
  //@ts-ignore
  const { afterPurchaseFlow, purchaseProgressType } = useRemoteConfig();
  const defaultStepsText = t(`${tKey}.${purchaseProgressType}`, { returnObjects: true }) as any;

  const steps = stepsText || defaultStepsText;

  const validCreateAccountPlaces = {
    beforeUpsales: 0,
    afterUpurchases: afterPurchaseFlow.length - 1,
  }

  const createAccountPlace = afterPurchaseFlow.indexOf('CREATE_ACCOUNT');

  const extraStepPlace =
    createAccountPlace === validCreateAccountPlaces.beforeUpsales ?
      validCreateAccountPlaces.beforeUpsales + 1 :
      createAccountPlace === validCreateAccountPlaces.afterUpurchases ?
        validCreateAccountPlaces.afterUpurchases : null;

  const stepsStatus = [...defaultStepsStatus];
  const isCreateAccountStep = afterPurchaseFlow.includes('CREATE_ACCOUNT') && extraStepPlace;

  const isDeLng = navigator.language.split("-")[0] === 'de';
  
  if (isCreateAccountStep) {
    stepsStatus.splice(extraStepPlace, 0, 'account');
  }
  const currentStepIndex = stepsStatus.findIndex((item) => item === currentStep)

  return (
    <div className={classNames(classes.progress, {[classes.narrow]: purchaseProgressType === 'single_word'})}>
      {
        stepsStatus.map((item, index) => {
          const stepText = steps[item as ProgressSteps];
          return purchaseProgressType === 'single_word' ?
            <SingleWordProgress
              key={item}
              text={stepText}
              status={getStepStatus(index, currentStepIndex)}
            />
            :
            <DefaultProgress
              key={item}
              text={stepText}
              addGap={isCreateAccountStep && !isDeLng}
              status={getStepStatus(index, currentStepIndex)}
            />
        })
      }
    </div >
  )
}
