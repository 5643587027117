import React, { ButtonHTMLAttributes, DetailedHTMLProps, } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

const LinkButton: React.FC<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = ({
  className,
  ...other
}) => (
  <button
    type="button"
    className={classNames(classes.root, className)}
    {...other}
  />
);

export default LinkButton;
