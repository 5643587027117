import React from 'react';
import classNames from 'classnames';

import { tm, TM } from '@web-solutions/module-localization';

import { Block } from 'core/ui-elements';
import { ProductDetails } from 'core/store/billing/selectors';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { PaymentModalType } from 'core/constants/remote-config';

import { useTimer } from 'core/payment/hooks/use-timer';

import classes from './style.module.scss';

interface Props {
  isActive: boolean,
  product: ProductDetails,
  onClick: (product: ProductDetails) => void,
};

export const Tile: React.FC<Props> = ({
  isActive,
  product,
  onClick,
}) => {
  const {
    discount,
    textLineThrough,
    subTitle,
    descriptionText,
    title,
    badgeTitle,
  } = product;

  const { showTimerInPlanBadge, paymentModalType } = useRemoteConfig();

  const timer = useTimer();

  const handleTileClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(product);
  };

  const isShown = badgeTitle || ((paymentModalType !== PaymentModalType.SIMPLE && paymentModalType !== PaymentModalType.SIMPLE_TITLE) && !!discount);

  return (
    <Block
      className={classNames(classes.tile, { [classes.isActiveTile]: isActive })}
      onClick={handleTileClick}
    >
      {isShown && (
        <div className={classes.timer_hat}>
          <div className={classes.discount}>{tm(badgeTitle, 'core.payment_popup.discount_save', { ...product })}</div>
          {(timer?.shown && showTimerInPlanBadge) && (
            <span className={classes.time_text}>{timer?.text}</span>
          )}
        </div>
      )}
      <span className={classes.actual_price}>{tm(descriptionText, '', { ...product })}</span>

      {title && <span className={classes.title}>{tm(title, '', { ...product })}</span>}
      {
        textLineThrough && (
          <span className={classes.textLineThrough}>{tm(textLineThrough, '', { ...product })}</span>
        )
      }
      <span className={classes.subTitle}><TM k={subTitle} defaultKey='' tOptions={product} /></span>
    </Block>
  )
}