import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PaymentSystem } from '@web-solutions/react-billing';
import Button from '@web-solutions/core/ui-elements/button';
import Analytics from '@web-solutions/module-analytics';

import { initMercadoPagoPayment } from '@web-solutions/core/store/billing/actions';
import { type ProductDetails } from '@web-solutions/core/store/billing/selectors';

import { MercadoPagoForm } from './form';

import { ReactComponent as Icon } from './icon.svg';
import classes from './style.module.scss';

const eventsCategory = 'mercadopago';

interface Props {
  product: ProductDetails,
  upsaleTrigger?: string,
}

export const MercadoPagoBtn: React.FC<Props> = ({
  product,
  upsaleTrigger,
}) => {
  const dispatch = useDispatch();

  const [isOpenForm, setIsOpenForm] = useState(false);

  const handleClick = useCallback(() => {
    Analytics.trackEvent(eventsCategory, 'open');
    setIsOpenForm(true);
  }, [])

  const handleSubmit = useCallback((data: { first_name: string, last_name: string, taxpayer_id: string, }) => {
    setIsOpenForm(false);

    Analytics.trackEvent('modal_payment', 'submit', {
      method: 'mercadopago',
      paymentSystem: PaymentSystem.SOLIDGATE,
      productId: product?.id,
      value: +product?.trialPriceAmount || +product?.amount,
      currency: product?.currency,
    });

    dispatch(initMercadoPagoPayment({
      product,
      trigger: upsaleTrigger,
      first_name: data.first_name,
      last_name: data.last_name,
      taxpayer_id: data.taxpayer_id,
    }))
      //@ts-ignore
      .catch(error => {
        Analytics.trackEvent('modal_payment', 'error', {
          message: error?.message,
          paymentSystem: error?.paymentSystem,
        });
      });
  }, [dispatch, product, upsaleTrigger])

  const handleClose = useCallback(() => {
    Analytics.trackEvent(eventsCategory, 'close');
    setIsOpenForm(false);
  }, []);

  return (
    <>
      <Button
        title={(<><Icon /> Mercado Pago</>)}
        className={classes.btn}
        onClick={handleClick}
      />
      <MercadoPagoForm
        isOpen={isOpenForm}
        onSubmit={handleSubmit}
        onCloseClick={handleClose}
      />
    </>
  );
};
