import classNames from 'classnames';

import { Button, Text, Title } from 'core/ui-elements';

import { T, t } from '../../../../../../module-localization';
import { IMAGES } from '../../images';

import classes from './style.module.scss';

interface BaseProps {
  subtitle: string;
  children: React.ReactElement;
  withFooter?: boolean;
  withButton?: boolean;
  onClick?: () => void;
}

const tKey = 'core.subscription.error_popup.decline_reasons.base';

export const Base: React.FC<BaseProps> = ({ onClick, withFooter, subtitle, children, withButton }) => {
  return (
    <div className={classes.wrap}>
      <img
        className={classes.img}
        src={IMAGES.CARD.src}
        srcSet={IMAGES.CARD.srcSet}
        alt='background'
      />
      <Title className={classes.title}>{t(`${tKey}.title`)}</Title>
      <Text className={classes.subtitle}>{subtitle}</Text>
      <div className={classNames(classes.content, { [classes.withFooter]: withFooter })}>
        {children}
      </div>
      {withFooter && <div className={classes.footer}>
        <Text className={classes.footerText}>
          <T k={`${tKey}.footer`} components={{ b: <span className={classes.highlight} /> }} />
        </Text>
      </div>}
      {withButton && <Button onClick={onClick} className={classes.btn} titleClassName={classes.btnTitle} title={t(`${tKey}.btn`)} />}
    </div>
  )
}