import type { FC } from 'react';

import { t } from '@web-solutions/module-localization';

import { Button, Modal } from 'core/ui-elements';

import { DoneIcon } from './icons';

import classes from './style.module.scss';

const tKey = 'core.contact_modal.success_popup';

interface SuccessPopupProps {
  active: boolean;
  onClosePopup: () => void;
}

export const SuccessPopup: FC<SuccessPopupProps> = ({ active, onClosePopup }) => (
  <Modal
    className={classes.container}
    isOpen={active}
    overlayClassName={classes.overlay}
  >
    <div className={classes.popup}>
      <div className={classes.texts}>
        <div className={classes.top}>
          <div className={classes.icon}>
            <DoneIcon className={classes.done_icon}/>
          </div>
          <p className={classes.title}>{t(`${tKey}.title`)}</p>
        </div>
        <p className={classes.text}>{t(`${tKey}.text`)}</p>
      </div>
      <Button
        title={t(`${tKey}.button`)}
        className={classes.button}
        onClick={onClosePopup}
      />
    </div>
  </Modal>
)
