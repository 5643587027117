import { LocalizedString } from "core/localization/constants/remote-config"
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { useAppSelector } from "@web-solutions/store/types"

import Analytics from '@web-solutions/module-analytics';
import { T, LString } from '@web-solutions/module-localization';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { Title, Section } from '@web-solutions/core/ui-elements';
import TrialReminder from '@web-solutions/core/payment/components/trial-reminder';
import PaymentDescription from '@web-solutions/core/payment/components/payment-description';
import { ProductDetails, selectSpecialOfferEndDate } from '@web-solutions/core/store/billing/selectors';

import { PlanType } from 'core/constants/remote-config';

import { FixedButton, type FixedButtonProps } from '../fixed-button';
import InjectedPaymentMethodSwitch from '../injected-payment-method/components/switch';
import { PaymentSafe } from '../../containers/payment-safe';
import { ProductsTexts } from '..';

import { PolicyText } from './policy-text';
import { Item } from './item';
import { Tile } from './tile';
import { PdfItem } from './pdf-item';
import { Feature } from './feature';
import { Ticket } from './ticket';
import { SinglePlan } from './single-plan';

import classes from './style.module.scss';

interface PlansProps {
  activeProduct: ProductDetails,
  products: ProductDetails[],
  showTerms?: boolean,
  onProductClick: (p: ProductDetails) => void,
  onFixedButtonClick?: FixedButtonProps['onClick'],
  onPayPalSubmit?: FixedButtonProps['onPayPalSubmit'],
  subtitle?: React.ReactNode,
  productsTexts: ProductsTexts[];
  plan?: PlanType
}

export const Plans: React.FC<PlansProps> = ({
  activeProduct,
  products,
  showTerms = true,
  onProductClick,
  onFixedButtonClick,
  onPayPalSubmit,
  subtitle,
  productsTexts,
  plan
}) => {
  const plansSectionId = 'plans';
  const productsListId = 'products-list';

  const lang:any = new URLSearchParams(window.location.search).get('lang') ?? 'en';

  const finalProductsTexts = productsTexts?.[lang] ?? productsTexts;

  //TODO: use in template instead
  const formattedProducts = products.map(product => ({
    ...product,
    descriptionText: productsTexts?.[lang] ?? product.descriptionText,
  }));

  const {
    buttonsConfigs,
    productsTitle,
    showPaymentSafeAtPlans,
    isInjectedPaymentMethodOnPage,
    isTrialPeriodReminder,
    specialOffer,
    planType,
    plansFeatures,
    showTermsAtPlans,
    policyLinks,
  } = useRemoteConfig();
  const locale: keyof LocalizedString = useAppSelector(state => state.locale.locale);

  const specialOfferEndDate = useSelector(selectSpecialOfferEndDate);

  const handleItemClick = useCallback(
    (selectedProduct: ProductDetails) => {
      Analytics.trackEvent('product', 'selected', { selectedProductId: selectedProduct.id });
      // @ts-ignore
      window.fbq('track', 'InitiateCheckout');
      onProductClick(selectedProduct);
    },
    [onProductClick],
  );

  const renderItems = useCallback(
    (type: PlanType) => products.map((product) => {
      const currentProductTexts = productsTexts.find((text) => text.id === product.id)!;

      switch (type) {
        case PlanType.HORIZONTAL:
          return (
            <Tile
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
            />
          );
        case PlanType.PDF:
          return (
            <PdfItem
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
            />
          );
        case PlanType.TICKET:
          return (
            <Ticket
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
              titleText={currentProductTexts.titleText}
              subTitleText={currentProductTexts.subTitleText}
              perText={currentProductTexts.perText}
              perTitle={currentProductTexts.perTitle}
            />
          )

          case PlanType.MODAL:
          return (
            <Item
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
              titleText={currentProductTexts.titleText}
              subTitleText={currentProductTexts.subTitleText}
              perText={currentProductTexts.perText}
              perTitle={currentProductTexts.perTitle}
            />
          )
        default:
          return (
            <Item
              key={product.id}
              isActive={activeProduct.id === product.id}
              onClick={handleItemClick}
              product={product}
              titleText={activeProduct.title?.[locale]!}
              subTitleText={activeProduct.subTitle?.[locale]!}
              perText={activeProduct?.perWeekText[locale] }
            />
          );
      }
    }),
    [products, activeProduct.id, handleItemClick, productsTexts],
  );

  const renderPlanFeatures = useCallback(() => plansFeatures.map((feature) => (
    <Feature text={feature} />
  )), [plansFeatures])

  const title = specialOfferEndDate
    ? <T k={'core.special_offer.products'} tOptions={{ discount: specialOffer.discount }} />
    : productsTitle[locale];

  const classContainer = classNames(classes.container, { [classes.horizontal]: planType === PlanType.HORIZONTAL });

  return (
    <Section className={classNames(classes.wrap)} id={plansSectionId}>
      {products.length === 1 && planType === PlanType.SINGLE ?
        <SinglePlan product={products[0]} />
        :
        <>
          {!!title  && !plan && <Title level="h2">{title}</Title>}
          {!!plansFeatures?.length && <div className={classes.plansFeatures}>{renderPlanFeatures()}</div>}
          {subtitle}
          <div className={classContainer}>
            <ul className={classes.productsList} id={productsListId}>{renderItems( plan ?? planType)}</ul>
          </div>
        </>
      }

      {
        isInjectedPaymentMethodOnPage && (
          <InjectedPaymentMethodSwitch />
        )
      }

      {
        (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) && <TrialReminder className={classes.reminder} />
      }

      {!!onFixedButtonClick && !!onPayPalSubmit && !plan &&
        <div className={classes.fixedBtnWrap}>
          <FixedButton
            activeProduct={activeProduct}
            buttonsConfigs={buttonsConfigs}
            onClick={onFixedButtonClick}
            id={productsListId}
            onPayPalSubmit={onPayPalSubmit}
          />
        </div>

      }
      <PolicyText />
      {!!onFixedButtonClick && !!onPayPalSubmit && plan &&
        <div className={classes.fixedBtnWrap}>
          <FixedButton
            activeProduct={activeProduct}
            buttonsConfigs={buttonsConfigs}
            onClick={onFixedButtonClick}
            id={'modal'}
            onPayPalSubmit={onPayPalSubmit}
            buttonName={specialOffer.button[locale]}
          />
        </div>

      }
      {
        showTerms && showTermsAtPlans &&
        !plan &&
        <PaymentDescription
          isShort
          activeProduct={activeProduct}
        />
      }
      {showPaymentSafeAtPlans &&
        <PaymentSafe isModal={false} />
      }

      {plan && (
        <div className={classes.guaranteedWrapper}>
          <div className={classes.guaranteeSave}><img src={require('./save.png')} width={16} height={20} alt='save sign image'/>
          <span>{specialOffer.guaranteedSaveText[locale]}</span></div>
          <img src={require('./cards.png')} width={255} height={33.76} alt='guarantee image'/>
          <p className={classes.guaranteedText}>
            {specialOffer.guaranteedText[locale as keyof LString]}
          </p>
          <div className={classes.guaranteedLinkWrapper}>
            <a
              className={classes.guaranteedLink}
              href={policyLinks.moneyBack}
              target={'_blank'}
              rel='noreferrer'
            >
             {specialOffer.guaranteedLink[locale as keyof LString]}
            </a>
          </div>
        </div>
      )}
    </Section>
  );
};
