import {
  AddPacksContent,
  AssistantContent,
  DaysPlanContent,
  DetailedReportContent,
  EarlyAccessContent,
  OfflineModeContent,
  SkipTrialContent,
  UnavailableProductPopup,
} from "core/constants/upsaleTypes";

import { LocalizedString } from "core/localization/constants/remote-config"

export interface RemoteConfigUpsales {
  upsaleRoutes: string[];
  unavailableProduct: string[];
  meditationEbookContent: any;
  pregnancyEbookContent: any;
  upsaleFlowMode: boolean;
  upsaleFlow: string[];

  upsaleSteps: {
    ADD_PACKS: LocalizedString;
    SKIP_TRIAL: LocalizedString;
    EARLY_ACCESS: LocalizedString;
    DETAILED_REPORT: LocalizedString;
    OFFLINE_MODE: LocalizedString;
    ACCESS_PRODUCT: LocalizedString;
    DAYS_PLAN: LocalizedString;
    ASSISTANT: LocalizedString;
    MEDITATION_EBOOK: LocalizedString;
    PREGNANCY_EBOOK: LocalizedString;
  };
  unavailableProductPopup: UnavailableProductPopup;
  addPacksContent: AddPacksContent;
  skipTrialContent: SkipTrialContent;
  earlyAccessContent: EarlyAccessContent;
  assistantContent: AssistantContent;
  daysPlanContent: DaysPlanContent;
  detailedReportContent: DetailedReportContent;
  offlineModeContent: OfflineModeContent;
}

export const INITIAL_CONFIG_UPSALES: RemoteConfigUpsales = {
  upsaleRoutes: [],
  unavailableProduct: [],
  pregnancyEbookContent: {},
  meditationEbookContent: {},

  upsaleFlowMode: false,
  upsaleFlow: [],
  unavailableProductPopup: {} as UnavailableProductPopup,
  addPacksContent: {} as AddPacksContent,
  skipTrialContent: {} as SkipTrialContent,
  earlyAccessContent: {} as EarlyAccessContent,
  daysPlanContent: {} as DaysPlanContent,
  assistantContent: {} as AssistantContent,
  detailedReportContent: {} as DetailedReportContent,
  offlineModeContent: {} as OfflineModeContent,
  upsaleSteps: {
    ADD_PACKS: {
      en: "Add Packs",
    } as LocalizedString,
    SKIP_TRIAL: {
      en: "Skip Trial",
    } as LocalizedString,
    EARLY_ACCESS: {
      en: "Early Access",
    } as LocalizedString,
    DETAILED_REPORT: {
      en: "Detailed Report",
    } as LocalizedString,
    OFFLINE_MODE: {
      en: "Offline Mode",
    } as LocalizedString,
    ACCESS_PRODUCT: {
      en: "Access Product",
    } as LocalizedString,
    DAYS_PLAN: {
      en: "90 Days Plan",
    } as LocalizedString,
    ASSISTANT: {
      en: "Assistant",
    } as LocalizedString,
    PREGNANCY_EBOOK: {
      en: "Pregnancy ebook",
    } as LocalizedString,
    MEDITATION_EBOOK: {
      en: "Meditation ebook",
    } as LocalizedString,
  },
};
