const validate = (val, field, rule) => {
  let isValid = true;

  switch (field) {
    case 'isEmail':
      isValid = isValid && emailValidator(val);
      break;
    case 'isText':
      isValid = isValid && minLengthValidator(val, rule);
      break;
    default:
      isValid = isNotEmpty(val);
  }

  return isValid;
};

const isNotEmpty = (val) => val.length > 0;

const emailValidator = val => {
  return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(val);
}

const minLengthValidator = (val, minLength) => val.length >= minLength;

export default validate;
