import { Text } from "core/ui-elements";

import { T, t } from "../../../../../../module-localization";
import { Base } from "../base"

import classes from '../../style.module.scss'

const tKey = 'core.subscription.error_popup.decline_reasons.card_issuer';

interface Props {
  onClick: () => void;
}

export const CardIssuer: React.FC<Props> = ({ onClick }) => {
  return <Base
    subtitle={t(`${tKey}.subtitle`)}
    withButton
    onClick={onClick}
  >
    <Text className={classes.baseText}>
      <T k={`${tKey}.text`} />
    </Text>
  </Base>
}