/* eslint-disable jsx-a11y/anchor-has-content */
import { Text } from "core/ui-elements";

import { T, t } from "../../../../../../module-localization";
import { Base } from "../base"

import classes from '../../style.module.scss'

const tKey = 'core.subscription.error_popup.decline_reasons.subscription_locked';

interface Props {
  onClick: () => void;
}

export const SubscriptionLocked: React.FC<Props> = ({ onClick }) => {
  return <Base
    subtitle={t(`${tKey}.subtitle`)}
    onClick={onClick}
  >
    <Text className={classes.baseText}>
      <T k={`${tKey}.text`}
        components={{ email: <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className={classes.email} /> }}
        tOptions={{ email: `${process.env.REACT_APP_SUPPORT_EMAIL}` }}
      />
    </Text>
  </Base>
}