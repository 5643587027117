import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isCookieModalShown: false,
  isCookieAccepted: true,
};


const slice = createSlice({
  name: 'cookie',
  initialState,
  reducers: {
    setCookiePolicy: (state, action: PayloadAction<boolean>) => {
      state.isCookieAccepted = action.payload;
      state.isCookieModalShown = true;
    },
  }
})

export const { setCookiePolicy } = slice.actions;

export { slice as cookieSlice };
