import { useState, useEffect, } from 'react';
import { useSelector } from 'react-redux'
import queryString from 'query-string';

import DeviceProps from '@magnus/react-native-device-props'

import {
  EMPTY_VALUE,
  type IDeviceInfo,
} from '@wowmaking/helpdesk';

import type { Purchase } from '@web-solutions/core/interfaces/billing';

//@ts-ignore
import { APP_NAME } from 'src/constants/general'

export const useGetDeviceInfoData = () => {
  const { utm_source, idfm } = queryString.parse(window.location.search);

  const initialDeviceInfoData: IDeviceInfo = {
    app_name: APP_NAME ?? EMPTY_VALUE,
    paid: EMPTY_VALUE,
    tracker: utm_source as string ?? EMPTY_VALUE,
    idfm: idfm as string ?? EMPTY_VALUE
  }

  const [deviceInfoData, setDeviceInfoData] = useState(() => initialDeviceInfoData)

  const purchased: boolean = useSelector((state: any) => state?.billing?.purchased)
  const purchase: Purchase | null = useSelector((state: any) => state?.billing?.purchase)

  const { plan_name } = purchase || {}

  useEffect(() => {
    Promise.all([
      DeviceProps.fetch(),
      DeviceProps.getAppVersion()
    ])
      .then(([{ os_name, os_version, device_model }, version]) => {
        setDeviceInfoData((prev) => ({
          ...prev,
          version,
          platform: os_name,
          platform_version: os_version,
          device_name: device_model
        }))
      })
      .catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    setDeviceInfoData((prev) => ({
      ...prev,
      paid: typeof purchased === 'boolean' ? String(purchased) : EMPTY_VALUE,
      productId: plan_name ?? EMPTY_VALUE,
    }))
  }, [purchased, plan_name])

  return deviceInfoData
}