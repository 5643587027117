import classnames from 'classnames';

import { Title, Text } from '@web-solutions/core/ui-elements';

import { ReactComponent as ArrowBackIcon } from '../icons/arrow-back.svg';

import classes from './style.module.scss';

interface HeaderProps {
  title: string,
  withBackButton?: boolean,
  subtitle?: string,
  content?: React.ReactNode,
  className?: string,
  titleClassName?: string,
  subtitleClassName?: string,
  withContent?: boolean,
  onBackClick: () => void,
}

export const Header: React.FC<HeaderProps> = ({
  withBackButton,
  title,
  subtitle,
  content,
  className,
  titleClassName,
  subtitleClassName,
  withContent = true,
  onBackClick
}) => {
  return <div className={classnames(className, classes.header)}>
    {withContent &&
      <>
        <Title level="h1" className={classnames(titleClassName, classes.title)}>{title}</Title>
        {content}
        {subtitle && <Text className={classnames(subtitleClassName, classes.text)}>{subtitle}</Text>}
      </>
    }
    {withBackButton && <button className={classes.back_button} onClick={onBackClick}>
      <ArrowBackIcon />
    </button>}
  </div>
}