import { useContext, createContext } from 'react';

import { Theme } from 'core/constants/theme';

export interface ThemeContext {
  theme: Theme;
}

const themeContext = createContext({} as ThemeContext);
export const ThemeContextProvider = themeContext.Provider

export const useThemeContext = () => {
  const context = useContext(themeContext);
  return context;
}