import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Block: React.FC<Props & Record<string, any>> = ({ children, className, ...rest }) => (
  <div className={classNames(classes.wrap, className)} {...rest}>
    {children}
  </div>
);

export default Block;
