import { useEffect } from 'react';
import queryString from 'query-string';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from 'core/constants/general';
import { Theme } from 'core/constants/theme';

import { ReactComponent as IconClose } from './close.svg';

import classes from './style.module.scss';

interface Props {
  link: string,
  enableClose: boolean,
  analyticsCategory: string,
  onSurveyFinish: () => void;
  theme?: Theme;
}

export const Survey: React.FC<Props> = ({ link, enableClose, analyticsCategory, onSurveyFinish, theme = Theme.light }) => {
  const idfm = queryString.parse(window.location.search).idfm;

  useEffect(() => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.OPEN);
  }, [analyticsCategory]);

  useEffect(() => {
    const handleMessage = (event: { data: string }) => {
      if (typeof event.data === 'string') {
        if (event?.data?.includes('Tally.FormSubmitted')) {
          const timer = setTimeout(() => {
            Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS);
            onSurveyFinish();
          }, 1500);
          return () => clearTimeout(timer);
        }
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [analyticsCategory, onSurveyFinish]);


  const handleCloseClick = () => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.CLOSE);
    onSurveyFinish();
  };

  return (
    <div className={classNames(classes.wrapper, { [classes.dark]: theme === Theme.dark })}>
      {enableClose &&
        <div className={classes.close} onClick={handleCloseClick}>
          <IconClose />
        </div>
      }
      <iframe
        src={queryString.stringifyUrl({ url: link, query: { idfm } })}
        title='tally'
      />
    </div>
  );
};