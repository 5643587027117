import React from 'react';

import { ModalDialog, Button, Input, Title } from '@web-solutions/core/ui-elements';
import { t } from '@web-solutions/module-localization';

import { ReactComponent as Icon } from '../icon.svg';

import classes from './style.module.scss';

interface Props {
  isOpen: boolean,
  onSubmit: (data: { first_name: string, last_name: string, taxpayer_id: string }) => void,
  onCloseClick: () => void,
}

export const MercadoPagoForm: React.FC<Props> = ({
  isOpen,
  onSubmit,
  onCloseClick,
}) => {
  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    e.preventDefault();

    const form = e.target as typeof e.target & {
      first_name: { value: string };
      last_name: { value: string };
      taxpayer_id: { value: string };
    };

    onSubmit({
      first_name: form.first_name.value,
      last_name: form.last_name.value,
      taxpayer_id: form.taxpayer_id.value,
    })
  }

  return (
    <ModalDialog
      isOpen={isOpen}
      className={classes.modal}
      onCloseClick={onCloseClick}
    >
      <Title
        level='h3'
        className={classes.title}
      >
        <Icon />
        Mercado Pago
      </Title>
      <form
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Input
          placeholder={t('core.first_name')}
          className={classes.input}
          name="first_name"
          required
        />
        <Input
          placeholder={t('core.last_name')}
          className={classes.input}
          name="last_name"
          required
        />
        <Input
          placeholder={t('core.mercado_taxpayer_id')}
          className={classes.input}
          name="taxpayer_id"
          required
        />
        <Button
          type="submit"
          title={t('_continue')}
          className={classes.btn}
        />
      </form>
    </ModalDialog>
  );
};
