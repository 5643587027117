import type { FC, PropsWithChildren, ComponentProps } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

export interface TextProps extends PropsWithChildren<ComponentProps<'p'>> { }

const Text: FC<TextProps> = ({ children, className, ...props }) => {
  const textStyle = classNames([classes.text, className]);

  const isArLng = navigator.language.split("-")[0] === 'ar';

  return <p dir={isArLng ? "rtl" : ""} className={textStyle} {...props}>{children}</p>;
};

export default Text;
