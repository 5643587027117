import _map from 'lodash/map';

import { t, tm } from '@web-solutions/module-localization';

const getTitleText = (text, tKeyText, period, params = {}) => {
  if (text && typeof text === 'string' && text[0] === '_') {
    return t(text);
  }

  return tm(text, `core.plans.${tKeyText}.${period}`, params);
};

export const getSubTitleText = (text, period, isTrial, isIntroductory, days, price) => {
  let tKeyPeriod = period;

  if (isTrial) {
    tKeyPeriod = 'trial';
  }

  if (isIntroductory) {
    tKeyPeriod = 'introductory';
  }

  return getTitleText(text, 'items_sub_titles', tKeyPeriod, { days, price });
};

export const getProductTitle = (product) => {
  const { title, period } = product;

  return getTitleText(title, 'items_titles', period);
};

export const getProductsTexts = (products, tKey) => {
  return _map(products, (product) => {
    const {
      subTitle,
      weekPrice,
      priceText,
      period,
      isTrial,
      trialDays,
      trialPrice,
      isIntroductory,
      accentTitle,
      accentSubTitle,
      perWeekText
    } = product;
    const priceForSubTitle = isTrial ? trialPrice : priceText;
    const titleText = getProductTitle(product);
    const subTitleText = getSubTitleText(subTitle, period, isTrial, isIntroductory, trialDays, priceForSubTitle);
    const perPeriodTKey = period?.indexOf('D') === 2 ? 'per_day' : 'per_week';
    const perText = product.attachment === 'specialOffer' ? perWeekText['en'] :tm(accentSubTitle, `${tKey}.${perPeriodTKey}`, product);
    const perTitle = tm(accentTitle || weekPrice, '', product);
    return {
      titleText,
      subTitleText,
      perText,
      perTitle,
      id: product.id
    }
  })
}

export const getPriceTitle = (config_price) => {
  const currencySymbol = config_price.charAt(0);
  const currencySuffix = config_price.match(/[a-zA-Z]+/g)?.join('');

  const numbersOnly = config_price.replace(/[^\d.]/g, "");
  const price = parseFloat(numbersOnly);

  const integerPart = Math.trunc(price);
  const fractionalPart = price.toFixed(2).split('.')[1];

  return {
    currencySymbol,
    integerPart,
    fractionalPart,
    currencySuffix
  }
}