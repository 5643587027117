import React from 'react';

import classes from './style.module.scss';

interface Props {
  text: string;
}

export const Feature: React.FC<Props> = ({ text }) => (
  <div className={classes.feature}>
    <div className={classes.dot}>
      <div className={classes.icon} />
    </div>
    <span className={classes.text}>{text}</span>
  </div>
)