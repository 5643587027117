import { AppLinks } from '@web-solutions/module-attribution'

export const APP_NAME = 'Brainary';

export const PROJECT_SUPPORT_SUBJECT = '[Brainary WEB] ';

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const APP_LINKS: AppLinks = {
  ADJUST_LINK: '',
  ADJUST_TOKEN: '',
  APPSFLYER_LINK: 'https://brainary.onelink.me/86Zs',
};
