import { useSelector } from 'react-redux'

import { t } from '../../localization';

import { selectOffers } from '../slice';

export const useOffer = (productId: string) => {
  const offers = useSelector(selectOffers);
  const offer = offers?.find((offer) => offer.id === productId);

  const period = t(`manage.period.${offer?.period}`);

  return { period, offer }
}