import { createAsyncThunk } from '@reduxjs/toolkit';

import DeviceProps from '@magnus/react-native-device-props';

import Analytics from '@web-solutions/module-analytics';

import { processPayment } from '../../api/payment';
import { recordTransaction } from '../../api/transaction';
import { Purchase } from '../../api/types';

export const handlePurchaseThunk = createAsyncThunk(
  'purchase/handlePurchase',
  async ({ recurlyId }: { recurlyId: string }, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { purchase: rcPurchase } = getState().billing;

    const purchaseData: Purchase = { email: rcPurchase?.email, token_id: rcPurchase?.token_id };

    try {
      const idfm = await DeviceProps.getIDFM();
      const chargeInvoice = await processPayment(purchaseData, recurlyId);
      await recordTransaction(chargeInvoice, idfm, purchaseData);
      Analytics.trackEvent('purchase_upsale', 'success', { product_id: recurlyId });
      return chargeInvoice;
    } catch (error: any) {
      Analytics.trackEvent('purchase_upsale', 'failed', {
        product_id: recurlyId,
        error: error.message,
      });
      return rejectWithValue(error.message);
    }
  }
);
