import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UpsaleState {
  currentStep: number;
  steps: string[];
  purchasedUpsaleIds: string[];
  isShowPopup: boolean;
  upsaleName: string | null;
  isNextStepBlocked: boolean;
  isLoading: boolean;
}

const initialState: UpsaleState = {
  currentStep: 0,
  steps: [],
  purchasedUpsaleIds: [],
  isShowPopup: false,
  upsaleName: null,
  isNextStepBlocked: false,
  isLoading: false,
};

const upsaleSlice = createSlice({
  name: "upsale",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.currentStep = Math.min(state.currentStep + 1, state.steps.length);
    },
    setCurrentStep: (state, action) => {
      let stepIndex = action.payload;
      while (state.purchasedUpsaleIds.includes(state.steps[stepIndex])) {
        stepIndex += 1;
      }
      state.currentStep = stepIndex;
    },
    markUpsaleAsPurchased: (state, action: PayloadAction<string>) => {
      state.purchasedUpsaleIds.push(action.payload);
    },
    setIsShowPopup: (
      state,
      action: PayloadAction<{
        isShow: boolean;
        upsaleName: string | null;
        isNextStepBlocked?: boolean;
      }>,
    ) => {
      state.isShowPopup = action.payload.isShow;
      state.upsaleName = action.payload.upsaleName;
      state.isNextStepBlocked = action.payload.isNextStepBlocked || false;
    },
    setSteps: (state, action: PayloadAction<string[]>) => {
      state.steps = action.payload;
      if (state.currentStep >= state.steps.length) {
        state.currentStep = 0;
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  nextStep,
  setCurrentStep,
  markUpsaleAsPurchased,
  setSteps,
  setIsShowPopup,
  setIsLoading,
} = upsaleSlice.actions;

export default upsaleSlice;
