export const REASONS_IDS = {
    "CHANGE_MIND": "change_mind",
    "NO_TIME": "no_time",
    "PROBLEMS": "problems",
    "DONT_LIKE": "dont_like",
    "TRIAL": "trial",
    "REPORT": "report",
    "LONGER_PLAN": "longer_plan",
    "DONT_USE": "dont_use",
    "PRICE": "price",
    "OTHER": "other"
}