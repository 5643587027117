import React, { useEffect } from 'react';
import Modal from 'react-modal';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from 'core/constants/general';
import { ROUTES } from 'core/constants/routes';

import classes from './style.module.scss';

interface Props {
  verifyUrl: string;
  onSuccess: () => void;
  onFail: () => void;
}

const category = '3ds_modal'

export const Verify3dsModal: React.FC<Props> = ({ verifyUrl, onSuccess, onFail }) => {
  const handleOnLoadIframe = (e: React.ChangeEvent<HTMLIFrameElement>) => {
    try {
      const pathName = e.target?.contentWindow?.location?.pathname;

      if (pathName === ROUTES.VERIFY_3DS_SUCCESS) {
        Analytics.trackEvent(category, EVENT_ACTION.SUCCESS);
        onSuccess();
      } else if (pathName === ROUTES.VERIFY_3DS_FAIL) {
        Analytics.trackEvent(category, 'fail');
        onFail();
      }
    } catch (error) { }
  }

  useEffect(() => {
    if (!!verifyUrl) {
      Analytics.trackEvent(category, EVENT_ACTION.OPEN);
    }
  }, [verifyUrl])

  return (
    <Modal
      isOpen={!!verifyUrl}
      style={{
        overlay: {
          zIndex: 100,
        }
      }}
      className={classes.iframe_modal}
    >
      <iframe
        onLoad={handleOnLoadIframe}
        title="verifyIframe"
        id="verifyIframe"
        className={classes.iframe}
        src={verifyUrl}
      />
    </Modal>
  )
};
