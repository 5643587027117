import classNames from "classnames";
import { useAppSelector } from "@web-solutions/store/types"

import { ComponentProps, type FC, Fragment } from "react";

import { useRemoteConfig } from "@web-solutions/base-app/hooks/use-remote-config";
import { EVENT_ACTION } from "@web-solutions/core/constants/general";

import Analytics from "@web-solutions/module-analytics";


import classes from "./style.module.scss";

const POLICY_TEXT_CONFIG = [
  "policy",
  "privacy_policy",
  "terms",
  "billing_terms",
  "and",
  "money_back",
] as const;

const POLICY_TEXT_TYPE_LOOKUP: PolicyTextLookup<"link" | "text"> = {
  privacy_policy: "link",
  terms: "link",
  billing_terms: "link",
  money_back: "link",
  policy: "text",
  and: "text",
};

type PolicyTextLookup<T extends string = string> = Record<
  (typeof POLICY_TEXT_CONFIG)[number],
  T
>;

export const PolicyText: FC<ComponentProps<"div">> = ({ className }) => {
  const { policyLinks, purchaseScreenData } = useRemoteConfig();

  const locale = useAppSelector((state) => state.locale.locale);
  const policyLinksLookup: Partial<PolicyTextLookup> = {
    privacy_policy: policyLinks.privacy,
    terms: policyLinks.termsOfUse,
    billing_terms: policyLinks.billingTerms,
    money_back: policyLinks.moneyBack,
  };

  const handleLinkClick = (link?: string) => () => {
    Analytics.trackEvent("sub_main_payment_policy", EVENT_ACTION.CLICK, {
      link,
    });
  };

  return (
    <div className={classNames(classes.confirmation, className)}>
      <p className={classNames(classes.text, classes.confirmation__text)}>
        {POLICY_TEXT_CONFIG.map((key) => (
          <Fragment key={key}>
            {POLICY_TEXT_TYPE_LOOKUP[key] === "link"
? (
              <a
                target="__blank"
                href={policyLinksLookup[key]}
                className={classNames(classes.text, classes.confirmation__link)}
                onClick={handleLinkClick(policyLinksLookup[key])}
              >
                {purchaseScreenData.agreement[key][locale]}
              </a>
            )
: (
              purchaseScreenData.agreement[key][locale]
            )}
            &nbsp;
          </Fragment>
        ))}
      </p>
    </div>
  );
};
