import { useSelector } from 'react-redux';

import { Button, LinkButton, Title } from "@web-solutions/core/ui-elements"
import { PaymentSystem } from "@web-solutions/react-billing";
import { selectSubscription, selectPaymentSystem } from '@web-solutions/manage-subscription/src/manage/slice';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { SubscriptionStatus } from "../../../types";
import { TM, t } from '../../../../localization';

import classes from '../style.module.scss';

interface FooterProps {
  tKey: string;
  onReactivateClick: () => void;
  onChangePlanClick: () => void;
  onCancelSubscriptionClick: () => void;
  onReturnToAppClick: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  tKey,
  onReactivateClick,
  onChangePlanClick,
  onCancelSubscriptionClick,
  onReturnToAppClick
}) => {

  const subscription = useSelector(selectSubscription);
  const paymentSystem = useSelector(selectPaymentSystem);
  const { mngSubMainPage } = useRemoteConfig();

  return (
    <>
      {subscription ? (
        <>
          {subscription.status === SubscriptionStatus.CANCELED || subscription.status === SubscriptionStatus.EXPIRED ?
            <>
              {(paymentSystem === PaymentSystem.SOLIDGATE || paymentSystem === PaymentSystem.RECURLY) && (
                <Button
                  onClick={onReactivateClick}
                  className={classes.btn}
                  title={t(`${tKey}.reactivate_btn`)}
                />
              )}
            </>
            :
            <>
              {(paymentSystem === PaymentSystem.SOLIDGATE || paymentSystem === PaymentSystem.RECURLY) && (
                <Button
                  onClick={onChangePlanClick}
                  className={classes.btn}
                  title={<TM k={mngSubMainPage?.changePlanButtonText} defaultKey={t(`${tKey}.change_plan_btn`)} />}
                />
              )}
              <LinkButton
                className={classes.btn}
                onClick={onCancelSubscriptionClick}
              >
                {t(`${tKey}.cancel_sub_btn`)}
              </LinkButton>
            </>
          }
        </>
      ) : (
        <>
          <Title level={'h1'}>{t(`${tKey}.no_subscription`)}</Title>
          <Button
            onClick={onReturnToAppClick}
            className={classes.btn}
            title={t(`${tKey}.return_to_app`)}
          />
        </>
      )}
    </>
  )

}