import React from 'react';
import classNames from 'classnames';

import { useAppSelector } from "@web-solutions/store/types"

import { t } from '@web-solutions/module-localization';

import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { PaymentModalType } from 'core/constants/remote-config';

import { useTimer } from '../../../payment/hooks/use-timer';

import classes from './style.module.scss';

const tKey = 'core.payment_popup';

interface DiscountWithTimerProps {
  discount?: number,
  badgeTitle?: string,
  centered?: boolean,
  disabled?: boolean,
}

export const DiscountWithTimer: React.FC<DiscountWithTimerProps> = ({
  discount,
  badgeTitle,
  centered,
  disabled,
}) => {
  const { showTimerInPlanBadge, paymentModalType, paymentPopup } = useRemoteConfig();
  const locale = useAppSelector(state => state.locale.locale);
  const timer = useTimer();
  const isShownTimer = timer?.shown && showTimerInPlanBadge;
  const discountTitle = badgeTitle || t(discount && isShownTimer ?`${tKey}.discount` : paymentPopup.discountSave[locale], { discount });
  const isShownTimerBlock = !!discount && isShownTimer && !disabled;

  const isShown = badgeTitle || ((paymentModalType !== PaymentModalType.SIMPLE && paymentModalType !== PaymentModalType.SIMPLE_TITLE) && !!discount);

  if (isShown) {
    return (
      <div className={classNames(classes.root, { [classes.centered]: centered, [classes.disabled]: disabled })}>
        <div className={classNames(classes.container)}>
          <div className={classes.timerContainer}>
            <span className={classes.text}>{discountTitle}</span>
            {isShownTimerBlock && (
              <span className={classes.timerText}>{timer?.text}</span>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};
