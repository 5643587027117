import classNames from 'classnames';

import classes from './style.module.scss';

interface Props {
  onCheckClick: () => void;
  isActive: boolean;
}

const RoundCheckbox: React.FC<Props> = ({ isActive, onCheckClick }) => (
  <div
    className={classNames(classes.round_checkbox, { [classes.checked]: isActive })}
    onClick={() => onCheckClick()}
  >
    <div className={classes.inner_circle} />
  </div>
);

export default RoundCheckbox;