/* eslint-disable jsx-a11y/anchor-has-content */
import { Text } from "core/ui-elements";

import { T, t } from "../../../../../../module-localization";
import { Base } from "../base"

import classes from '../../style.module.scss'

const tKey = 'core.subscription.error_popup.decline_reasons.token_not_found';

interface Props {
  onClick: () => void;
}

export const TokenNotFound: React.FC<Props> = ({ onClick }) => {

  return <Base subtitle={t(`${tKey}.subtitle`)} withButton withFooter onClick={onClick}>
    <>
      <Text className={classes.baseText}>
        <T k={`${tKey}.text`} components={{ b: <span className={classes.highlight} /> }} />
      </Text>
      <Text className={classes.baseText}>
        <T
          k={`${tKey}.email`}
          components={{ email: <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className={classes.email} /> }}
          tOptions={{ email: `${process.env.REACT_APP_SUPPORT_EMAIL}` }}
        />
      </Text>
    </>
  </Base>
}