import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { Preloader } from '@web-solutions/core/ui-elements';
import { t } from '@web-solutions/module-localization';

import { ProgressWrapper } from '../../components/progress-wrapper';

import { useSliceDispatch, cancelSubscription } from '../../slice';

import { useNavigateManage } from '../../hooks/use-navigate-next-screen';

import { ErrorModal } from '../../components/error-modal';
import { requestCancel } from '../../../terminate/slice';
import { useManageContext } from '../../hooks/use-manage-context';

const tKey = "manage.terminate_subscription"

const category = 'manage_terminate_subscription'

export const TerminateSubscription: React.FC = () => {
  const dispatch = useSliceDispatch();

  const { navigateNextScreen } = useNavigateManage();
  const { terminateFeatures, progressContent } = useManageContext();
  const { mngSubTerminatePage, mngSubEmailCancelPage, mngFreeGiftCancelPage } = useRemoteConfig();

  const fixedButton = mngSubTerminatePage?.fixedButton;
  const fixedLink = mngSubTerminatePage?.fixedLink;

  const [errorMessage, setErrorMessage] = useState('');

  const requestCancelSub = () => {
    dispatch(requestCancel({ trigger: 'manage' }))
      .unwrap()
      .then(() => {
        navigateNextScreen(true);
      })
      .catch((error: any) => {
        console.warn(error);
        const message = error?.data?.message ? error.data.message : t(`${tKey}.error_msg`);
        Analytics.trackEvent(category, 'error', { message: message });
        toast(message);
      });
  }

  const unsubscribe = () => {
    if (mngSubEmailCancelPage.enabled) {
      requestCancelSub();
    } else {
      dispatch(cancelSubscription({ withFreeGift: mngFreeGiftCancelPage?.enabled }))
        .unwrap()
        .then(() => {
          Analytics.trackEvent(category, EVENT_ACTION.SUCCESS);
          navigateNextScreen(true);
        }, err => {
          Analytics.trackEvent(category, EVENT_ACTION.ERROR, { message: err.message });
          setErrorMessage(err.message);
        });
    }
  }

  useEffect(() => {
    if (!mngSubTerminatePage.enabled) {
      unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleErrorCloseClick = () => {
    Analytics.trackEvent(`${category}_error_modal`, EVENT_ACTION.CLOSE);
    setErrorMessage('');
  }

  const handleRetryClick = () => {
    Analytics.trackEvent(`${category}_terminate_retry`, EVENT_ACTION.CLICK);
    setErrorMessage('')
    unsubscribe();
  }

  return (
    <>
      {mngSubTerminatePage.enabled ?
        <ProgressWrapper
          fixedButton={fixedButton}
          fixedLink={fixedLink}
          category={category}
          onSubmit={unsubscribe}
        >
          {terminateFeatures}
          {progressContent}
        </ProgressWrapper> :
        <Preloader />
      }
      <ErrorModal
        tKey={`${tKey}.error_modal`}
        isOpen={!!errorMessage}
        message={errorMessage}
        onButtonClick={handleRetryClick}
        onCloseClick={handleErrorCloseClick}
      />
    </>
  );
};