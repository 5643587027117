import _map from 'lodash/map';
import _capitalize from 'lodash/capitalize';
import React from 'react';
import classNames from 'classnames';

import { t, tm, } from '@web-solutions/module-localization';

import { ProductDetails } from 'core/store/billing/selectors';
import TrialsLeft, { TrialsLeftType } from 'core/payment/components/trials-left';

import { ReactComponent as CheckIcon } from './check.svg';

import classes from './style.module.scss';

const tKey = 'core.plans';

interface PdfItemProps {
  isActive: boolean,
  product: ProductDetails,
  onClick: (product: ProductDetails) => void,
};

export const PdfItem: React.FC<PdfItemProps> = ({
  isActive,
  product,
  onClick,
}) => {
  const handleItemClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(product);
  };

  const isSoldOut = !!product.sold?.total && !product.sold.left;
  const tScope = isSoldOut ? 'handcover' : 'pdf';

  // @ts-ignore
  const title = product.title = tm(product.title, `${tKey}.${tScope}.title`) as unknown as string;
  const features = t(`${tKey}.${tScope}.features`, { returnObjects: true }) as unknown as string[];

  return (
    <div className={classNames(classes.wrap, { [classes.disabled]: isSoldOut })}>
      {product.trialsLeftBadge && <TrialsLeft type={TrialsLeftType.BADGE_PRODUCT} />}
      <button
        type="button"
        onClick={handleItemClick}
        className={classNames(classes.container, { [classes.active]: isActive })}
        disabled={isSoldOut}
      >
        <div className={classes.header}>
          <p className={classes.title}>
            {title}
          </p>
        </div>
        <div className={classes.features}>
          {_map(features, f => (
            <p className={classes.feature}>
              <CheckIcon />
              {f}
            </p>
          ))}
        </div>
      </button>
      <div className={classes.details}>
        <div className={classes.prices}>
          <span className={classes.lineThrough}>
            {tm(product.textLineThrough, '', product)}
          </span>
          <span className={classes.price}>
            {tm(product.priceText, '', product)}
          </span>
        </div>
        <span className={classes.checkmark}>
          <CheckIcon />
        </span>
      </div>
      <span className={classes.soldOut}>
        {_capitalize(t('core.plans.sold_out'))}
      </span>
    </div>
  );
};
