import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@web-solutions/core/ui-elements';

import { t } from '../../localization';

import { ROUTES } from '../routes';

import { TerminateTemplate } from '../components/template';

const tKey = 'terminate.error';

export const TerminateErrorScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const code = location.state?.code;
  const isCodeMessage = t(`${tKey}.messages`, { returnObjects: true })?.[code];
  const codeMessage = t(`${tKey}.messages.${isCodeMessage ? code : '1'}`);
  const errorMessage = code ? codeMessage : t(`${tKey}.description`);

  const handleButtonClick = (e: Event) => {
    e.preventDefault();
    navigate(ROUTES.MAIN);
  };

  const renderChildren = () => <Button onClick={handleButtonClick} type="button" title={t(`${tKey}.button_title`)} />;

  return (
    <TerminateTemplate title={t(`${tKey}.title`)} text={errorMessage}>
      {renderChildren()}
    </TerminateTemplate>
  );
};
