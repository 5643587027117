import { configureStore, createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

//@ts-ignore
import Billing from '@web-solutions/module-billing';
import Analytics from '@web-solutions/module-analytics';
import { resetPurchase } from '@web-solutions/core/store/billing/actions';

interface State {
  email: string | null,
  pending: boolean,
}

const initialState: State = {
  email: null,
  pending: false,
};

export const slice = createSlice({
  name: 'terminate',
  initialState,
  reducers: {
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    processEmail: (state, action: PayloadAction<string>) => {
      Analytics.setUserProperty('email', action.payload);
      state.email = action.payload;
    },
  },
})

const { setPending } = slice.actions;

export const cancelSubscription = createAsyncThunk(
  `${slice.name}/cancelSubscription`,
  async (a, { dispatch }) => {
    dispatch(setPending(true));
    const p = queryString.parse(window.location.search, { decode: false });
    const token = p.token || p.q;
    const sig = p.sig;
    const email = p.email;

    try {
      if (token || (sig && email)) {
        const params = token ? { token: token } : { sig: sig, email: email };
        const r = await Billing.submitCancelSubscription(params);
        dispatch(resetPurchase());
        return r;
      } else {
        throw Error('Link is invalid');
      }
    } finally {
      dispatch(setPending(false));
    }
  }
)

export const requestCancel = createAsyncThunk(
  `${slice.name}/requestCancel`,
  async (data: { trigger?: string } | undefined = {}, { dispatch, getState }) => {
    const email = selectEmail(getState());
    const state = getState() as any
    const subEmail = state?.manage?.subscription?.email

    dispatch(setPending(true));

    try {
      const r = await Billing.requestCancel({ email: email ? email : subEmail, ...data });
      return r
    }
    finally {
      dispatch(setPending(false));
    }
  });

export const { processEmail } = slice.actions;

export const selectEmail = (state: any) => (state[slice.name] as State).email;
export const selectPending = (state: any) => (state[slice.name] as State).pending;

const store = configureStore({
  reducer: slice.reducer,
})

export type AppDispatch = typeof store.dispatch
export const useSliceDispatch: () => AppDispatch = useDispatch;
