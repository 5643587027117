import TrialsLeft, {
  TrialsLeftType,
} from "@web-solutions/core/payment/components/trials-left";

import {
  ButtonBehavior,
  ButtonConfig,
  ButtonPlacementType,
} from "@web-solutions/core/payment/constants";
import { ProductDetails } from "@web-solutions/core/store/billing/selectors";
import { remoteConfigSelector } from "@web-solutions/core/store/remote-config/selectors";
import InjectedPaymentMethodButton from "@web-solutions/core/summary/injected-payment-method/components/button";

import Analytics from "@web-solutions/module-analytics";

import { t } from "@web-solutions/module-localization";
import classNames from "classnames";

import { useFixedButton } from "core/hooks/use-fixed-button";

import { Button, Portal } from "core/ui-elements";
import {
  updateButtonConfig,
  useButtonTitleByConfig,
} from "core/utils/button-configurator";
import React from "react";
import { useSelector } from "react-redux";
import { Textfit } from "react-textfit";

import { useTimer } from "../../payment/hooks/use-timer";

import classes from "./style.module.scss";

const tKey = "core.payment_popup";

export interface FixedButtonProps {
  activeProduct: ProductDetails;
  buttonsConfigs: { [key in ButtonPlacementType]: ButtonConfig };
  onlyFixed?: boolean;
  onClick: (type: ButtonPlacementType, forceOpen?: boolean) => void;
  id?: string;
  onPayPalSubmit?: (data: any) => void;
  buttonName?: string;
  oneProductBlock?: boolean;
}

export const FixedButton: React.FC<FixedButtonProps> = ({
  activeProduct,
  buttonsConfigs,
  onlyFixed,
  onClick,
  id,
  onPayPalSubmit,
  buttonName,
  oneProductBlock,
}) => {
  const plansButtonConfig = buttonsConfigs[ButtonPlacementType.PLANS] || {};
  const fixedButtonConfig = buttonsConfigs[ButtonPlacementType.FIXED] || {};
  const { isInjectedPaymentMethodOnPage } = useSelector(remoteConfigSelector);

  const title = useButtonTitleByConfig(
    updateButtonConfig({
      config: plansButtonConfig,
      updatingFields: { title: activeProduct.buttonTitle },
    }),
    "core.get_my_plan",
    activeProduct,
  );
  const fixedBtnTitle = useButtonTitleByConfig(
    fixedButtonConfig,
    "core.get_my_plan",
    activeProduct,
  );
  const isFixedButtonModalBehavior =
    fixedButtonConfig.behavior === ButtonBehavior.MODAL;

  const { fixed, isVisibleProducts } = useFixedButton({
    productsListSelector: id,
    buttonsSelector: ".sub-static-btn",
    productSelector: ".product-item",
  });

  const timer = useTimer();

  const handleButtonClick = (e: React.SyntheticEvent) => {
    Analytics.trackEvent("products_button", "click");
    e.preventDefault();
    onClick(ButtonPlacementType.PLANS);
  };

  const handleFixedButtonClick = (e: React.SyntheticEvent) => {
    Analytics.trackEvent("fixed_button", "click");
    e.preventDefault();
    onClick(ButtonPlacementType.FIXED, isVisibleProducts.current);
  };

  const handleCardClick = () => {
    onClick(ButtonPlacementType.FIXED, isVisibleProducts.current);
  };

  return (
    <>
      {!onlyFixed && (
        <div className={classNames(classes.content, classes.buttonMargin)}>
          {activeProduct.trialsLeftBadge && (
            <TrialsLeft type={TrialsLeftType.BADGE_BUTTON} />
          )}
          <Button
            onClick={onlyFixed ? handleFixedButtonClick : handleButtonClick}
            className={classNames("sub-static-btn", classes.button, {
              [classes.invisible]: fixed,
            })}
            titleClassName={classes.buttonTitleStyle}
            title={
              <Textfit mode="multi" max={22} className={classes.signboardTitle}>
                {title}
              </Textfit>
            }
            flare={!!plansButtonConfig?.enabledFlareAnimation}
          />
          {isInjectedPaymentMethodOnPage && !!onPayPalSubmit && (
            <InjectedPaymentMethodButton
              activeProduct={activeProduct}
              onPayPalSubmit={onPayPalSubmit}
              onCardClick={handleCardClick}
            />
          )}
        </div>
      )}
      <Portal>
        <div
          className={classNames(classes.fixed, {
            [classes.hidden]: !fixed,
          })}
        >
          {timer?.shown && !onlyFixed && !oneProductBlock && (
            <div className={classes.discount} onClick={handleFixedButtonClick}>
              <div className={classes.timer}>
                <b>{timer?.minutes}</b>
                <span className={classes.colon}>:</span>
                <b>{timer?.seconds}</b>
              </div>
              {!!activeProduct?.discount && (
                <div>
                  <span className={classes.desc}>
                    {t(`${tKey}.discount_fixed`, {
                      discount: activeProduct?.discount,
                    })}
                  </span>
                </div>
              )}
            </div>
          )}
          <div className={classes.content}>
            <Button
              onClick={handleFixedButtonClick}
              className={classNames(classes.button, "sub-fixed-btn")}
              titleClassName={classes.buttonTitleStyle}
              title={fixedBtnTitle ?? ""}
              flare={!!fixedButtonConfig?.enabledFlareAnimation}
            />
            {isInjectedPaymentMethodOnPage &&
              isFixedButtonModalBehavior &&
              !!onPayPalSubmit && (
                <InjectedPaymentMethodButton
                  activeProduct={activeProduct}
                  onPayPalSubmit={onPayPalSubmit}
                  onCardClick={handleCardClick}
                />
              )}
          </div>
        </div>
      </Portal>
    </>
  );
};
