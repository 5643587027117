import { useState } from "react";
import classNames from "classnames";
import { useSelector } from 'react-redux';

import { t } from "@web-solutions/module-localization";

import { useRemoteConfig } from "core/hooks/use-remote-config";
import { COUNTRY_VAT } from "core/payment/constants";

import { ReactComponent as Arrow } from '../../../icons/arrow.svg';

import classes from "./style.module.scss";

interface VarPriceProps {
  priceText: string;
  vat: string;
  vatPrice: string;
}

const tKey = 'core.payment_popup';

export const VatPrice: React.FC<VarPriceProps> = ({ vat, vatPrice, priceText }) => {

  const [display, setDisplay] = useState(false);

  const { displayVAT, configVAT } = useRemoteConfig();
  const countryCode = useSelector((state: any) => state?.billing?.countryCode)
  const displayTaxes = displayVAT && COUNTRY_VAT[countryCode];

  const handleClick = () => {
    setDisplay(!display)
  }

  if (!displayTaxes) {
    return null;
  }

  if (configVAT.view === 'simple') {
    return <div className={classNames(classes.row, classes.vatRow)}>
      <p className={classes.vatText}>
        {vatPrice}
        {' + '}
        {vat}
        {' '}
        {t(`${tKey}.vat`)}
      </p>
    </div>
  }

  const { vat: vatPercent } = COUNTRY_VAT[countryCode]

  return <>
    <div className={classes.vat_wrapper}>
      <div onClick={handleClick} className={classNames(classes.vat_title, { [classes.collapsed]: !display })}>
        {t(`${tKey}.vat_text`)} <Arrow />
      </div>
      {display && <div className={classes.table}>
        <div className={classes.row}>
          <div className={classes.vat_text}>
            {t(`${tKey}.vat`)} {vatPercent}%
            {configVAT.withGeo && !!COUNTRY_VAT[countryCode]?.name && <span className={classes.country}>{COUNTRY_VAT[countryCode]?.name}</span>}
          </div>
          <div className={classes.vat_text}>{vat}</div>
        </div>
        <div className={classes.row}>
          <div className={classes.vat_text}>{t(`${tKey}.total.total`)}</div>
          <div className={classes.vat_text}>{priceText}</div>
        </div>
      </div>}
    </div>
  </>
}