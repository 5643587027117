import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { selectPaymentSystem } from 'core/store/billing/selectors';
import { PaymentSafe } from 'core/containers/payment-safe';

import PadleForm from '../../components/paddle-form';
import PaymentDescription from '../../components/payment-description';

import { ProductInfo } from '../product-info';

import classes from './style.module.scss';

const BasePaddleModalBody = ({
  activeProduct,
  onSubmit,
  onSuccess,
  onError,
  options,
  onApplePayClick,
  onApplePaySubmit,
  onGooglePayClick,
  onGooglePaySubmit,
  onUserInfoChange,
  subtitle,
                               onSaveTokenOneTime
}) => {
  const paymentSystem = useSelector(selectPaymentSystem);

  const {
    isShowSafeCheckout,
    showTermsAtPlans,
  } = useSelector(remoteConfigSelector);

  const cardFormProps = useMemo(() => ({
    product: activeProduct,
    options,
    paymentSystem,
    formClassName: 'payment-form',
    onSubmit,
    onSuccess,
    onError,
    onUserInfoChange,
    onApplePayClick,
    onApplePaySubmit,
    onGooglePayClick,
    onGooglePaySubmit,
  }), [
    activeProduct,
    options,
    paymentSystem,
    onSubmit,
    onSuccess,
    onError,
    onUserInfoChange,
    onApplePayClick,
    onApplePaySubmit,
    onGooglePayClick,
    onGooglePaySubmit,
    onSaveTokenOneTime
  ]);

  return (
    <div className={classes.contentContainer}>
      <div className={classes.wrap}>
        <ProductInfo
          product={activeProduct}
          subtitle={subtitle}
        />

        <div id={options?.applePayButtonParams?.containerId}></div>
        <div id={options?.googlePayButtonParams?.containerId}></div>

        <PadleForm {...cardFormProps} />

        {isShowSafeCheckout ? <PaymentSafe /> : null}

        {!showTermsAtPlans && (
          <PaymentDescription
            activeProduct={activeProduct}
          />
        )}
      </div>
    </div>
  );
};

BasePaddleModalBody.propTypes = {
  activeProduct: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onUserInfoChange: PropTypes.func.isRequired,
  options: PropTypes.object,
};

BasePaddleModalBody.defaultProps = {
  options: null,
};

export default BasePaddleModalBody;
