import _memoize from 'lodash/memoize';

declare global {
  interface Window {
    ApplePaySession: any;
  }
}

export const checkPaymentMethod = _memoize(function checkPaymentMethod(withActiveCard: boolean): Promise<boolean> {
  try {
    const p = withActiveCard
      ? window.ApplePaySession.canMakePaymentsWithActiveCard(process.env.REACT_APP_APPLE_PAY_MERCHANT_ID || 'merchant.app.pulsebit.sub')
      : Promise.resolve(window.ApplePaySession.canMakePayments());

    return p.catch(() => {
      return false;
    });
  } catch (ex) {
    return Promise.resolve(false);
  }
});
