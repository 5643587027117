import React, { InputHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

type Props = InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, Props>(({ className, ...rest }, ref) => (
  <input dir={'auto'} ref={ref} className={classNames(classes.input, className)} {...rest} />
));

export default Input;
