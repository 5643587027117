
import { useSelector } from 'react-redux';

import { remoteConfigSelector } from '@web-solutions/core/store/remote-config/selectors';

import { type RemoteConfig } from '../constants/remote-config';

export function useRemoteConfig<T = RemoteConfig>() {
  return useSelector(remoteConfigSelector) as T;
}
