import React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import { Preloader } from '@web-solutions/core/ui-elements';

import { PaymentSystem, type SolidgatePayPalOrder, } from '../constants';

// @ts-ignore
import classes from './PayPal.module.css';

const BTN_ID = 'paypal-button';


interface SolidgatePayPalButtonProps {
  productId: string,
  orders: Record<string, SolidgatePayPalOrder>,
  className?: string,
  style?: any,
  onClick: () => void,
  onSubmit: () => void,
  onSuccess: (p: { amount: number, currency: string, transaction_id: string, method: string }) => void,
  onCancel: () => void,
  onError: (e: any) => void,
}

export const SolidgatePayPalButton: React.FC<SolidgatePayPalButtonProps> = React.memo(({
  productId,
  orders,
  className,
  style,
  onClick,
  onSubmit,
  onSuccess,
  onCancel,
  onError,
}) => {
  const [order, setOrder] = React.useState<SolidgatePayPalOrder | null>(null);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    const upd = orders[productId];
    if (upd?.order && upd !== order) {
      setReady(false);
      setOrder(null);
      setTimeout(() => {
        setOrder(upd);
      })
    }
  }, [setOrder, productId, orders, order, onError]);


  const handleReady = React.useCallback(() => {
    setReady(true);
  }, []);

  React.useEffect(() => {
    if (order) {
      var elem = document.getElementById(BTN_ID);
      if (elem) {
        const handleError = function handleError(e: any) {
          const error = e?.detail?.data?.error || e?.detail?.error || e?.error || e;
          onError({
            code: error?.code,
            message: error?.messages?.[0] || error?.message,
            paymentSystem: PaymentSystem.SOLIDGATE,
            method: 'paypal',
          });
        }

        const nadleDone = function nadleDone(e: any) {
          const order = e?.detail?.data?.order;
          if (order?.status === 'approved') {
            order.amount /= 100;
            order.transaction_id = order.subscription_id;
            order.paymentSystem = PaymentSystem.SOLIDGATE;
            onSuccess(order);
          } else {
            handleError(e);
          }
        }

        elem.addEventListener('button-ready', handleReady, false);
        elem.addEventListener('button-click', onClick, false);
        elem.addEventListener('button-cancel', onCancel, false);
        // elem.addEventListener('button-error', handleError, false);

        elem.addEventListener('order-started-processing', onSubmit, false);
        elem.addEventListener('order-processed', nadleDone, false);
        elem.addEventListener('order-already-processed', nadleDone, false);

        return () => {
          elem?.removeEventListener('button-ready', handleReady);
          elem?.removeEventListener('button-click', onClick);
          elem?.removeEventListener('button-cancel', onCancel);
          // elem?.removeEventListener('button-error', handleError);

          elem?.removeEventListener('order-started-processing', onSubmit);
          elem?.removeEventListener('order-processed', nadleDone);
          elem?.removeEventListener('order-already-processed', nadleDone);
        };
      }
    }
  }, [order, onClick, onCancel, onError, onSubmit, onSuccess, handleReady]);

  if (!order) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(classes.wrapper, className)}
        style={style}
      >
        <Btn />
        {!ready && (
          <Preloader className={classes.spinner} />
        )}
      </div>
      <Helmet>
        <script
          type="text/javascript"
          src={order?.pay_form?.script_url}
          data-height={55}
          data-shape="rect"
          data-label="paypal"
        />
      </Helmet>
    </>
  );
}, (prevProps, nextProps) => prevProps.orders?.[prevProps.productId] === nextProps.orders?.[nextProps.productId])

const Btn = React.memo(() => (
  <div id={BTN_ID} />
));
