import { useEffect } from "react"
import { useSelector } from "react-redux"

interface CookieClearProps {
  children: React.ReactElement
}

function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const name = cookie.split("=")[0]
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const CookieClear: React.FC<CookieClearProps> = ({ children }) => {

  const isCookieAccepted = useSelector((state: any) => state.cookie.isCookieAccepted)

  useEffect(() => {
    if (!isCookieAccepted) {
      const id = setInterval(() => {
        deleteAllCookies();
      }, 500);

      return () => {
        clearInterval(id)
      }
    }
  }, [isCookieAccepted])


  return <>
    {children}
  </>
}