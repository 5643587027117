import React from 'react';

import { type ProductDetails } from 'core/store/billing/selectors';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { ModePayPal } from 'core/constants/billing';
import { type Subscription } from 'core/interfaces/billing';

import Native from './native';
import Solidgate from './solidgate';

interface Props {
  product: ProductDetails,
  onSubmit: (formData: any) => void,
  onSuccess: (p: Subscription) => void,
  onError: (e: any) => void,
  className?: string,
  style?: any,
}

export const PayPalBtn: React.FC<Props> = ({
  product,
  onSubmit,
  onSuccess,
  onError,
  className,
  style,
}) => {
  const { modePayPal } = useRemoteConfig();

  return modePayPal === ModePayPal.SOLIDGATE
    ? (
      <Solidgate
        product={product}
        className={className}
        style={style}
        onSuccess={onSuccess}
        onError={onError}
      />
    )
    : (
      <Native
        product={product}
        onSubmit={onSubmit}
        className={className}
        style={style}
      />
    );
};
