import type { RemoteConfig } from '../../constants/remote-config';

export const SET = 'REMOTE_CONFIG/SET';

interface Set {
  type: typeof SET;
  payload: {
    'cfg': RemoteConfig,
  },
}

export type RemouteConfigActionTypes = Set
