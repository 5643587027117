import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { Survey } from '@web-solutions/core/containers/survey'

interface Props {
  onSurveyFinish: () => void;
}

export const TerminateSurvey: React.FC<Props> = ({ onSurveyFinish }) => {
  const { link, enableClose } = useRemoteConfig().manageSurvey;

  return (
    <Survey
      link={link.terminate}
      enableClose={enableClose}
      analyticsCategory="terminate_survey"
      onSurveyFinish={onSurveyFinish}
    />
  );
};
