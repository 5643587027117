import React, { useCallback, } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { CardForm, PaymentSystem } from '@web-solutions/react-billing';

import Analytics from '@web-solutions/module-analytics';

import { setPaddleConfig, createCheckout } from 'core/store/billing/actions';

import classes from './style.module.scss';
import './style.scss';

const PaddleForm = ({
  product,
  onSubmit,
  onSuccess,
  onError,
  onUserInfoChange,
  onApplePayClick,
  onApplePaySubmit,
  onGooglePayClick,
  onGooglePaySubmit,
  options,
  formClassName,
}) => {
  const dispatch = useDispatch();
  const paddleConfig = useSelector(state => state.billing.paddleConfig);

  const handleFormReady = useCallback(({ checkoutId, productId } = {}) => {
    Analytics.trackEvent('paddle', 'ready', { checkoutId, productId });
    productId = String(productId);
    dispatch(setPaddleConfig({ ...(paddleConfig || {}), checkoutId, productId }));
    dispatch(createCheckout({ checkoutId, productId }))
  }, [paddleConfig, dispatch]);

  const handleEvent = useCallback((event, params) => {
    Analytics.trackEvent('paddle', event, params);
    if (event === 'payment_method_selected') {
      dispatch(setPaddleConfig({ ...(paddleConfig || {}), paymentMethod: params?.method }));
      dispatch(createCheckout({ checkoutId: paddleConfig.checkoutId, productId: paddleConfig.productId, paymentMethod: params?.method }))
    } else if (event === 'payment_method_change') {
      dispatch(setPaddleConfig({ ...(paddleConfig || {}), paymentMethod: '' }));
      dispatch(createCheckout({ checkoutId: paddleConfig.checkoutId, productId: paddleConfig.productId, paymentMethod: '' }))
    }
  }, [dispatch, paddleConfig]);

  const handleSolidGateSuccess = useCallback((transaction) => {
    onSuccess({
      ...transaction,
      price_id: product.solidgateId,
    });
  }, [onSuccess, product]);

  return (
    <>
      {product?.solidgateId && (
        <CardForm
          paymentSystem={PaymentSystem.SOLIDGATE}
          product={{ ...product, id: product.solidgateId }}
          onSubmit={onSubmit}
          onSuccess={handleSolidGateSuccess}
          onError={onError}
          onUserInfoChange={onUserInfoChange}
          onApplePayClick={onApplePayClick}
          onApplePaySubmit={onApplePaySubmit}
          onGooglePayClick={onGooglePayClick}
          onGooglePaySubmit={onGooglePaySubmit}
          options={{ ...(options || {}), formParams: { ...(options?.formParams || {}), enabled: false } }}
          buttonClassName={classes.hidden}
        />
      )}
      <CardForm
        product={product}
        onReady={handleFormReady}
        onSuccess={onSuccess}
        onError={onError}
        onUserInfoChange={onUserInfoChange}
        onEvent={handleEvent}
        formClassName={formClassName}
        options={options}
      />
    </>
  );
};

PaddleForm.propTypes = {
  product: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onUserInfoChange: PropTypes.func.isRequired,
  options: PropTypes.object,
  formClassName: PropTypes.any,
};

PaddleForm.defaultProps = {
  options: null,
};

export default PaddleForm;
