import { FC, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import Analytics from '@web-solutions/module-analytics';

import { ThemeContextProvider } from 'core/hooks/use-theme-context';
import { Theme } from 'core/constants/theme';
import { CookieClear } from 'core/containers/cookie-clear';

import { initLocalization } from 'src/localization';

import packageInfo from '../package.json';

import configureStore from './store';

import 'react-toastify/dist/ReactToastify.min.css';
import 'src/styles/global.scss';
import 'src/styles/index.scss';
import 'src/styles/variables.css';

interface AppProps {
  AppRouter: FC;
}

const { store, persistor } = configureStore();

const App: FC<AppProps> = ({ AppRouter }) => {
  useEffect(() => {
    console.log(`App version: ${packageInfo.version}`);

    Analytics.init(process.env.REACT_APP_MAGNUS_TOKEN!, packageInfo.version);

    const pType = window.location.pathname.split('/')[1];
    const p = new URLSearchParams(window.location.search);

    const variant = p.get('variant');
    if (variant) {
      Analytics.setUserProperty('variant', variant);
    }

    if (pType !== 'terminate') {
      (async () => {
        try {
          const { load } = await import('./store/app/actions');
          store.dispatch(load());
        } catch (error) {
          console.error('Failed to load app actions:', error);
        }
      })();
    }

    try {
      initLocalization();
    } catch (error) {
      console.error('Localization initialization failed:', error);
    }
  }, []);

  return (
    <CookieClear>
      <ThemeContextProvider value={{ theme: Theme.light }}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
          <ToastContainer position="bottom-center" autoClose={5000} closeOnClick />
        </PersistGate>
      </ThemeContextProvider>
    </CookieClear>
  );
};

export const render = (AppRouter: FC) => {
  const container = document.getElementById('root');

  if (!container) {
    console.error('Root container not found!');
    return;
  }

  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App AppRouter={AppRouter} />
      </PersistGate>
    </Provider>
  );
};
