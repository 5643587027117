import queryString from 'query-string';

import Mutator from '@magnus/react-native-mutator';

import Web2App from '@wowmaking/web2app';

export async function trackPurchaseEvent(transaction: any) {
  if (process.env.REACT_APP_APPY_CNT_URL) {
    const country_code = await Mutator.getCountryCode();
    const attribution = Web2App.getAttribution();
    const p = queryString.parse(window.location.search);
    fetch(process.env.REACT_APP_APPY_CNT_URL, {
      method: 'POST',
      mode: 'no-cors',
      body: JSON.stringify({
        id: transaction.transactionId,
        plan: transaction.productId,
        email: transaction.email,
        provider: transaction.paymentSystem,
        attribution: {
          media_source: processSource(attribution.source || ''),
          campaign_id: attribution.c_id,
          campaign_name: attribution.c,
          adset_id: attribution.adset_id,
          adset_name: attribution.adset,
          ad_id: attribution.ad_id,
          ad_name: attribution.ad,
          country_code,
          fbc: attribution.web_facebook_click_id,
          fbp: attribution.web_facebook_browser_id,
          event_source_url: attribution.site_url,
          client_user_agent: attribution.web_client_user_agent,
          config: queryString.stringifyUrl({ url: window.location.pathname, query: { mode: p.mode } }),
        },
      }),
    });
  }
}

function processSource(source: string) {
  if (/facebook/i.test(source)) {
    return 'facebook';
  } else if (/google/i.test(source) || /adwords/i.test(source)) {
    return 'adwords';
  } else if (/tiktok/i.test(source)) {
    return 'facebook';
  } else {
    return source;
  }
}
